import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { useAuthStore } from '../stores/authStore'
import * as trial from '../utils/trial'

export async function authGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
): Promise<void> {
    const authStore = useAuthStore()
    if (!authStore) {
        throw new Error('Auth store is not defined')
    }

    const requiresAuth = 'authRequired' in to.meta ? to.meta.authRequired : true

    //If the user isn't logged in, and the path required auth redirect them to login
    if (!authStore.isAuthenticated && requiresAuth && !to.path.includes('/login') ) {
        return next('/login')
    } else {
        if(authStore.isAuthenticated) {
            const sendToUpgrade = await trial.isExpired() 
            //If the user is logged in, but they aren't on the upgrade page and they are past the trial period, redirect them to the upgrade page
            if(sendToUpgrade && !to.path.includes('/upgrade')) {
                return next('/upgrade')
            }
        }
        return next()
    }
}
