import { defineStore } from 'pinia'
import type { User } from '@supabase/supabase-js'
import { supabase } from '@/utils/supabaseClient'
import { useStorage } from '@vueuse/core'
import type { RemovableRef } from '@vueuse/shared'
import * as types from "../utils/supabaseTypes"

const baseUrl = import.meta.env.VITE_BASE_URL;

type UserProfile = types.Database['public']['Tables']['profiles']['Row']

type State = {
  currentUser: RemovableRef<User | null>
  currentUserProfile: RemovableRef<UserProfile | null>
}

type Getters = {
  isAuthenticated(): boolean
}

type Actions = {
  loadUser(): void
  clearUser(): void
  login(email: string, password: string): Promise<Error | null>
  signup(email: string, password: string): Promise<Error | null>
  logout(): void
  resetPassword(email: string): Promise<Error | null>
  updateUserPassword(new_password: string): Promise<Error | null>
  updateEmail(email: string): Promise<Error | null>
}

export const useAuthStore = defineStore<'authStore', State, Getters, Actions>(
  'authStore',
  {
    state() {
      return {
        currentUser: useStorage('currentUser', {} as User),
        currentUserProfile: useStorage('currentUserProfile', {} as UserProfile),
      };
    },
    getters: {
      isAuthenticated() {
        if(!this.currentUser) return false
        else if (Object.keys(this.currentUser).length === 0) return false
        else return true
      }
    },
    actions: {
      async loadUser() {
        try {
            this.currentUser = (await supabase.auth.getUser()).data.user
            if(!this.currentUser) throw new Error('No user')
            this.currentUserProfile = (await supabase.from('profiles')
            .select('*')
            .eq('id', this.currentUser.id)
            .single()).data
        } catch (error) {
            console.error('Error loading user:', error)
        }
      },
      clearUser() {
        this.currentUser = null
        this.currentUserProfile = null
      },
      async login(email: string, password: string) {
        const response = await supabase.auth.signInWithPassword({
          email,
          password,
        })

        if (response.error) {
          console.error('Error signing in:', response.error)
          return response.error as Error
        } else {
          this.currentUser = response.data.user

          const { data, error } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', this.currentUser?.id)
            .single()

          if (error) {
            console.error(error)
            return error as unknown as Error
          } else {
            this.currentUserProfile = data
            return null
          }
        }
      },
      async signup(email: string, password: string) {
        const response = await supabase.auth.signUp({
          email,
          password,
        })

        if (response.error) {
          console.error('Error resetting password:', response.error)
          return response.error as Error
        } else {
          return null
        }
      },
      async logout() {
        await supabase.auth.signOut()
        this.clearUser()
      },
      async resetPassword(email: string) {
        const response = await supabase.auth.resetPasswordForEmail(email, {
          redirectTo: baseUrl+'reset-password',
        })

        if (response.error) {
          console.error('Error resetting password:', response.error)
          return response.error as Error
        } else {
          return null
        }
      },
      async updateUserPassword(new_password: string) {
        const response = await supabase.auth.updateUser({password: new_password})
        
        if (response.error) {
          console.error('Error resetting password:', response.error)
          return response.error as Error
        } else {
          return null
        }
      },
      async updateEmail(email: string) {
        const response = await supabase.auth.updateUser({email: email})
        
        if (response.error) {
          console.error('Error resetting email:', response.error)
          return response.error as Error
        } else {
          if(this.currentUser) this.currentUser.email = email
          return null
        }
      }
    }
  }
)