
<script setup lang="ts">
    import { Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue'
    import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
    import { RouterLink, useRouter } from 'vue-router'
    import { useAuthStore } from '../stores/authStore'
    import { useChartStore } from '@/stores/chartStore'

    const authStore = useAuthStore()
    const chartStore = useChartStore()
    const router = useRouter()

    const navigation = [
        { name: 'Charts', href: '/', current: true }
    ]

    const logout = async () => {
      try {
        await authStore.logout()
        chartStore.$reset()
        router.push('/login')
      } catch (error) {
        console.error(error)
      }
    }
    
    const goTo = async (href: string) => {
      const navigationResult = await router.push(href)

      if (navigationResult) {
        console.log(navigationResult)
      }
    }
</script>

<template>
    <Disclosure as="nav" class="border-b border-gray-200 bg-white" v-slot="{ open }">
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 justify-between">
          <div class="flex">
            <div class="flex flex-shrink-0 items-center">
              <img class="block h-8 w-auto lg:hidden" src="../assets/logo.png" alt="chartjuice" />
              <img class="hidden h-8 w-auto lg:block" src="../assets/logo.png" alt="chartjuice" />
            </div>
            <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              <a v-for="item in navigation" :key="item.name" @click="goTo(item.href)" class="nav-item inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
            </div>
          </div>
          <div class="flex">
            <!-- Profile dropdown -->
            <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              <a href="mailto:support@chartjuice.com" class="nav-item inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium">Help</a>
              <RouterLink to="/settings" class="nav-item inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium">Settings</RouterLink>
              <a @click="logout" class="nav-item inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium">Logout</a>
            </div>
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton class="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
              <span class="absolute -inset-0.5"></span>
              <span class="sr-only">Open main menu</span>
              <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:hidden">
        <div class="space-y-1 pb-3 pt-2">
          <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href" class="disclosure-button">{{ item.name }}</DisclosureButton>
          <DisclosureButton href="/settings" as="a" class="disclosure-button" >Settings</DisclosureButton>   
        </div>
      </DisclosurePanel>
    </Disclosure>
</template>

<style scoped>
    .disclosure-button {
        @apply border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800 block border-l-4 py-2 pl-3 pr-4 text-base font-medium;  
    }

    .disclosure-button:active, .disclosure-button:focus {
        @apply border-purple-500 bg-purple-50 text-purple-700 !important;
    }

    .router-link-active {
        @apply border-purple-500 text-gray-900 !important;
    }

    .nav-item {
        @apply border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700;
        cursor: pointer;
    }
</style>