import { useAuthStore } from '../stores/authStore'

function calculateDifference(date1: string | number | Date, date2: string | number | Date) {
     // Convert the dates to timestamps
     const timestamp1 = new Date(date1).getTime()
     const timestamp2 = new Date(date2).getTime()
 
     // Calculate the difference in milliseconds
     const difference = timestamp2 - timestamp1

     return difference
}

// Function to check if date2 is 14 days or more after date1
function isPastTrialPeriod(date1: string | number | Date, date2: string | number | Date) {  
    // Calculate the difference in milliseconds
    const difference = calculateDifference(date1, date2)

    // 14 days in milliseconds
    const fourteenDaysInMillis = 14 * 24 * 60 * 60 * 1000

    // Check if the difference is greater or equal to 14 days
    return difference >= fourteenDaysInMillis
}

export async function isExpired() {
    const authStore = useAuthStore()
    if (!authStore) {
        throw new Error('Auth store is not defined')
    }

    await authStore.loadUser()

    if(!authStore.currentUser) throw new Error('No user')
    if(!authStore.currentUserProfile) throw new Error('No user profile')

    const pastTrialPeriod = isPastTrialPeriod(authStore.currentUser?.created_at, new Date())
    return pastTrialPeriod && authStore.currentUserProfile?.customer_id === null
}

export async function isActive() {
    const authStore = useAuthStore()
    if (!authStore) {
        throw new Error('Auth store is not defined')
    }

    await authStore.loadUser()

    if(!authStore.currentUser) throw new Error('No user')
    if(!authStore.currentUserProfile) throw new Error('No user profile')

    const pastTrialPeriod = isPastTrialPeriod(authStore.currentUser?.created_at, new Date())
    return !pastTrialPeriod && authStore.currentUserProfile?.customer_id === null
}

export async function daysRemaining() {
    const authStore = useAuthStore()
    if (!authStore) {
        throw new Error('Auth store is not defined')
    }

    if(!authStore.currentUser) throw new Error('No user')
    if(!authStore.currentUserProfile) throw new Error('No user profile')

    const millisecondsFromStart = calculateDifference(authStore.currentUser?.created_at, new Date())
    const daysFromStart = Math.floor(millisecondsFromStart / (1000 * 60 * 60 * 24))
    
    return 14 - daysFromStart
}