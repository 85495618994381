import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

/**
 * Sets the document title and meta tags based on the route being navigated to.
 *
 * @param {RouteLocationNormalized} to - The destination route.
 * @param {RouteLocationNormalized} from - The source route.
 * @param {NavigationGuardNext} next - The navigation guard next function.
 * @return {Promise<void> | void} - A Promise that resolves when the meta tags are set, or undefined.
 */
export function metaGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> | void {
  const nearestWithTitle: RouteLocationNormalized['matched'][number] | undefined = to.matched
    .slice()
    .reverse()
    .find((r: RouteLocationNormalized['matched'][number]) => r.meta && r.meta.title);

  const nearestWithMeta: RouteLocationNormalized['matched'][number] | undefined = to.matched
    .slice()
    .reverse()
    .find((r: RouteLocationNormalized['matched'][number]) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta: RouteLocationNormalized['matched'][number] | undefined = from.matched
    .slice()
    .reverse()
    .find((r: RouteLocationNormalized['matched'][number]) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title as string;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title as string;
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).forEach((el) =>
    el.parentNode?.removeChild(el)
  );

  if (!nearestWithMeta) return next();

  (nearestWithMeta.meta.metaTags as Record<string, string>[]).forEach((tagDef: Record<string, string>) => {
    const tag: HTMLMetaElement = document.createElement('meta');

    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key]);
    });

    tag.setAttribute('data-vue-router-controlled', '');

    document.head.appendChild(tag);
  });

  next();
}
