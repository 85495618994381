<script setup lang="ts">
  import { RouterView } from 'vue-router'
  import { useAuthStore } from '@/stores/authStore'
  import TopNav from './components/CJTopNav.vue'
  import Banner from './components/CJBanner.vue'
  import '@vueform/slider/themes/default.css'

  const authStore = useAuthStore()

</script>

<template>
  <Banner v-if="authStore.isAuthenticated" />
  <TopNav v-if="authStore.isAuthenticated" />
  <main>
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <Suspense>
          <!-- main content -->
          <component :is="Component"></component>

          <!-- loading state -->
          <template #fallback>
            <h2 class="text-xl mx-auto font-bold leading-tight tracking-tight text-gray-900">Loading...</h2>
            
            <img src="./assets/hippo2-transparent.png" width="400" class="mx-auto" alt="hippoplotamus" />
          </template>
        </Suspense>
      </template>
    </RouterView>
  </main>
</template>
