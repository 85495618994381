<script setup lang="ts">
    import { XMarkIcon } from '@heroicons/vue/20/solid'
    import { ref } from 'vue'
    import { RouterLink } from 'vue-router'
    import * as trial from '../utils/trial'

    const isActive = ref(false)

    trial.isActive().then((active) => {
        isActive.value = active
    })
 
    const daysRemaining = ref()
    
    trial.daysRemaining().then((days) => {
        daysRemaining.value = days
    })

    const dismiss = ref(false)
</script>

<template>
    <div v-if="isActive && !dismiss" class="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <p class="text-sm leading-6 text-white">
        <RouterLink to="/upgrade">
            {{ daysRemaining }} days left in your free trial. Click here to upgrade now&nbsp;<span aria-hidden="true">&rarr;</span>
        </RouterLink>
        </p>
        <div class="flex flex-1 justify-end">
        <button @click="dismiss = true" type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]">
            <span class="sr-only">Dismiss</span>
            <XMarkIcon class="h-5 w-5 text-white" aria-hidden="true" />
        </button>
        </div>
    </div>
</template>