import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from '@/guards/auth'
import { metaGuard } from '@/guards/meta'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'charts',
      meta: { 
        authRequired: true,
        title: 'ChartJuice - My charts'
      },
      component: () => import('../views/ChartsView.vue')
    },
    {
      path: '/chart/:id',
      name: 'chart',
      meta: { 
        authRequired: true ,
        title: 'ChartJuice - Chart Builder'
      },
      component: () => import('../views/ChartView.vue')
    },
    {
      path: '/signup',
      name: 'signup',
      meta: { 
        authRequired: false,
        title: 'ChartJuice - Signup' 
      },
      component: () => import('../views/SignupView.vue')
    },
    {
      path: '/confirm-signup',
      name: 'confirm-signup',
      meta: { 
        authRequired: false,
        title: 'ChartJuice - Confirm signup' 
      },
      component: () => import('../views/ConfirmSignupView.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: { 
        authRequired: false,
        title: 'ChartJuice - Login'
      },
      component: () => import('../views/LoginView.vue')
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      meta: { 
        authRequired: false,
        title: 'ChartJuice - Forgot password'
      },
      component: () => import('../views/ForgotPasswordView.vue')
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      meta: { 
        authRequired: true,
        title: 'ChartJuice - Reset password'
      },
      component: () => import('../views/PasswordResetView.vue')
    },
    {
      path: '/upgrade',
      name: 'upgrade',
      meta: { 
        authRequired: true,
        title: 'ChartJuice - Upgrade'
      },
      component: () => import('../views/UpgradeView.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      meta: { 
        authRequired: true,
        title: 'ChartJuice - Settings'
      },
      component: () => import('../views/SettingsView.vue')
    }
  ]
})

router.beforeEach(authGuard)
router.beforeEach(metaGuard)

export default router
