import { defineStore } from 'pinia'
import { supabase } from '@/utils/supabaseClient'

export const useChartStore = defineStore(
  'chartStore',
  {
    state: () => ({
      charts: <any>[]
    }),
    getters: {
      getChart: (state) => (id: string) => {
        return state.charts.find((chart: any) => chart.id == id)
      }
    },
    actions: {
      async loadCharts() {
        const { data, error } = await supabase
          .from('charts')
          .select('*')
          .order('created_at', { ascending: false })
        if (error) {
          console.error(error)
        } else {
          this.charts = data
        }
      },
      async createChart(name: string) {
        const { data, error } = await supabase
          .from('charts')
          .insert({ name })
          .select()

        if (error) {
          console.error(error)
          return error
        } else {
          this.charts.push(data[0])
          return data[0]
        }
      },
      async saveChart(id: string) {
        const chart = this.getChart(id)

        const { data, error } = await supabase
          .from('charts')
          .update(chart)
          .eq('id', id)
          .select();

        if (error) {
          console.error(error)
          return error
        } else {
          return null
        }
      }
    }
  }
)